import { t } from "i18next";
import { CHECKOUT_OPTIONS } from ".";

const methodsExplainedCompleted = [
    {
        label: CHECKOUT_OPTIONS.FULL_PAYMENT,
        description: t(
            "Pay the total amount of your travel package now to confirm your reservation instantly. This option ensures all your selected services are secured without delays",
        ),
    },
    {
        label: CHECKOUT_OPTIONS.PARTIAL,
        description: t(
            "Pay just 10% of your travel package now to secure your reservation. This option reserves your selected items, with the remaining balance payable later",
        ),
    },
    {
        label: CHECKOUT_OPTIONS.ASSESSOR,
        description: t(
            "Select this option to receive personalized assistance. Our team will reach out to help you secure your reservation and finalize your booking",
        ),
    },
    {
        label: CHECKOUT_OPTIONS.TRANSFER,
        description: t(
            "Choose this option to pay via bank transfer. Our team will provide you with the necessary details to complete your booking",
        ),
    },
];

const methodsExplainedIsNextPayment = [
    {
        label: CHECKOUT_OPTIONS.NEXT_PAYMENT,
        description:
            "You've paid the deposit to secure your reservation. Please complete the remaining balance to finalize your travel plan",
    },
    methodsExplainedCompleted[2],
];
const methodExplainedPartialWithFlights = {
    label: CHECKOUT_OPTIONS.PARTIAL_FLIGHTS,
    description: t(
        "Pay now only 10% of your travel package + flight reservation to guarantee your reservation. This option allows you to reserve the selected items and pay the rest later.",
    ),
};

export {
    methodsExplainedCompleted,
    methodsExplainedIsNextPayment,
    methodExplainedPartialWithFlights,
};
